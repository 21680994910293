import {FC, ReactNode} from "react";
import SectionLayout from "@/layouts/SectionLayout";
import FlexCol from "@/components/centralbrisbanedental/elements/flex/FlexCol/FlexCol";
import FlexRow from "@/components/centralbrisbanedental/elements/flex/FlexRow/FlexRow";
import Heading from "@/components/centralbrisbanedental/content/Heading/Heading";
import Text from "@/components/centralbrisbanedental/content/Text/Text"
import Paragraph from "@/components/centralbrisbanedental/content/Paragraph/Paragraph";
import List from "@/components/centralbrisbanedental/content/List/List";
import RichList from "@/components/centralbrisbanedental/content/List/RichList";
import ImageElement from "@/components/centralbrisbanedental/content/Image/Image";

interface ContentEntries {
  type: 'paragraph' | 'orderedList' | 'heading' | 'highlight'
  content: string | string[]
}

interface Content {
  leftEntries: ContentEntries[]
  rightEntries?: ContentEntries[]
}

interface TwoColumnContentProps {
  children?: ReactNode | ReactNode[]
  title?: string;
  heading?: string;
  headingRight?: string;
  headingFirst?: string;
  content?: any;
}

const renderContent = (contentMap: any[]) => {

  return contentMap.map((contentItem: any,
    index: number) => {
    switch (contentItem.element) {
      case "flexCol":
        return <FlexCol
          classes={contentItem.classes && contentItem.classes}
        >
          {contentItem.children !== undefined ? (
            <>
              {renderContent(contentItem.children)}
            </>
          ) : null}
        </FlexCol>
      case "flexRow":
        return <FlexRow
          classes={contentItem.classes && contentItem.classes}
        >
          {contentItem.children !== undefined ? (
            <>
              {renderContent(contentItem.children)}
            </>
          ) : null}
        </FlexRow>
      case "heading":
        return <Heading
          type={contentItem.type}
          value={contentItem.value}
          classes={contentItem.classes && contentItem.classes}
        />
      case "paragraph":
        return <Paragraph
          type={contentItem.type}
          value={contentItem.value}
          spanClasses={contentItem.spanClasses && contentItem.spanClasses}
          classes={contentItem.classes && contentItem.classes}
        />
      case "image":
        return <ImageElement
          imageProperties={contentItem.imageProperties && contentItem.imageProperties}
          imageClasses={contentItem.imageClasses && contentItem.imageClasses}
          crop={contentItem.crop && contentItem.crop}
        />
      case "richList":
        return <RichList
          type={contentItem.type}
          value={contentItem.value}
        />
      default:
        return
    }
  })

}

const TwoColumnContent: FC<TwoColumnContentProps> = ({
  children,
  title,
  heading,
  headingRight,
  headingFirst,
  content
}) => {

  return (
    <SectionLayout
      anchor="what_is_it"
      classes={{
        container: true,
        margin: "mx-auto",
        overflow: "hidden",
        padding: "py-4 px-4 md:py-12",
      }}
    >
      {/* NOTE Title & Heading Row */}
      <FlexCol
        classes={{
          display: "flex flex-col",
          padding: "lg:px-8"
        }}
      >
        {headingFirst && (
          <Text
            type="span"
            value={headingFirst}
            classes={{
              dynamicColour: "#ff6600",
              fontWeight: "bold",
              letterSpacing: "tracking-wide",
              lineHeight: "leading-6",
              uppercase: true,
            }}
          />
        )}
        {title && (
          <Heading
            type="h2"
            value={title}
            classes={{
              margin: "mt-2",
              fontSize: "text-2xl md:text-4xl",
              fontWeight: "bold",
              letterSpacing: "tracking-tight",
              colour: "text-gray-900",
            }}
          />
        )}
        {heading && (
          <Text
            type="span"
            value={heading}
            classes={{
              dynamicColour: "#ff6600",
              fontWeight: "bold",
              letterSpacing: "tracking-wide",
              lineHeight: "leading-6",
              uppercase: true,
            }}
          />
        )}
      </FlexCol>
      <>
        {content !== undefined ? (
          <>
            {renderContent(content)}
          </>
        ) : null}
      </>
    </SectionLayout>
  )
}

export default TwoColumnContent;
